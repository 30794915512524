import { defaultDirection } from "../constants/defaultValues";
import { auth, firestore, storage } from "./Firebase";
import moment from 'moment'
import { NotificationManager } from "../components/common/react-notifications";
import Axios from "axios";
import { isPointInPolygon } from "geolib";
import axios from "axios";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];

    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};
export const uploadThumb = async (data) => {
  const res = await axios.post('https://ikpmkejexdrbwts4d3w5256rtu0xgbaj.lambda-url.me-south-1.on.aws/', data)
  return res
};
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      resolve(e.target.result)
    }
  })
};
export const uploadPicture = (pictures, ref, parentId, childId, type, documentID) => {

  pictures.forEach(async (element) => {
    let base64 = await getBase64(element.data)
    let awsResp;
    switch (type) {
      case 'variations': case 'professionals': case 'banners': case 'SOA':
        awsResp = await uploadThumb({ data: base64, key: `${ref}/${parentId}/${type}/${documentID}/${childId}/${Date.now()}` })
        if (awsResp?.data) {
          firestore().collection(ref).doc(parentId).collection(type).doc(documentID).set({ [childId]: [awsResp?.data], lastUpdate: Date.now() }, { merge: true })
        }
        break;
      default:
        awsResp = await uploadThumb({ data: base64, key: `${ref}/${parentId}/${childId}/${Date.now()}` })
        if (awsResp?.data) {
          firestore().collection(ref).doc(parentId).set({ [childId]: [awsResp?.data], lastUpdate: Date.now() }, { merge: true })
        }
        break;
    }

  });
};

export const uploadFile = (files, ref, parentId, childId, type, documentID) => {
  const [{ name }] = files;
  const file = files?.[0]
  // (professionalPictures, 'providers', provider_id, 'picture', 'professionals', id)
  if (!file) return;
  const storageRef = storage().ref().child(ref).child(parentId).child(childId)
  const uploadTask = storageRef.put(file);
  switch (type) {
    case 'professionals': case 'SOA':
      uploadTask.on("state_changed",
        (snapshot) => { },
        (error) => {
          // alert(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firestore().collection(ref).doc(parentId).collection(type).doc(documentID).set({ [childId]: { name: name, license: downloadURL }, lastUpdate: Date.now() }, { merge: true })
          })
        }
      );
      break;
    default:
      uploadTask.on("state_changed",
        (snapshot) => { },
        (error) => {
          // alert(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firestore().collection(ref).doc(parentId).set({ [childId]: { name: name, license: downloadURL }, lastUpdate: Date.now() }, { merge: true })
          })
        }
      );
      break;
  }


};
export const GetTimingSlots = (timings) => {
  if (!timings) {
    return []
  }
  let sorted = timings?.value?.sort((a, b) => b.start - a.start);
  let optimized = sorted?.reduce((val, currentVal) => {
    if (val?.[currentVal.key]) {
      val[currentVal.key].push(currentVal)
    } else {
      val[currentVal.key] = [currentVal]
    }
    return val
  }, {})

  let intersection = Object.values(optimized)?.reduce((val, currentVal) => {
    currentVal.map((rec, index) => {
      let previous = val?.[index]
      if (previous) {
        let start = rec.start > previous.start ? rec.start : previous.start
        let end = rec.end < previous.end ? rec.end : previous.end
        val[index] = { start, end }
      } else {
        val[index] = rec
      }
      return false
    })
    return val
  }, [])

  let slots = intersection?.reduce((val, currentVal) => {
    // return ({})
    for (
      let time = currentVal.start;
      time <= currentVal.end;
      time += 3600000
    ) {
      let slot = {
        start: time,
        end: time + 3600000,
        id: `${moment(time).format('hh:mm A')} - ${moment(time).add(60, 'minutes').format('hh:mm A')}`
      }

      if (!val?.some(r => r.id === slot.id)) {
        val.push(slot)
        val = val.sort((a, b) => a.start - b.start)
      }
    }
    return val
  }, [])

  return slots

}
export function makeid(length) {
  var result = Math.floor(Math.random(new Date().getTime()) * 90000000) + 10000000

  // var result = '';
  // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // var charactersLength = characters.length;
  // for (var i = 0; i < length; i++) {
  //   result += characters.charAt(Math.floor(Math.random() *
  //     charactersLength));
  // }
  return result;
}
export const createNotification = (className, msg, title) => {
  let cName = className || "";

  switch (className) {
    case "primary":
      return NotificationManager.primary(
        title,
        msg,
        3000,
        null,
        null,
        cName
      );
    case "secondary":
      return NotificationManager.secondary(
        title,
        msg,
        3000,
        null,
        null,
        cName
      );
    case "info":
      return NotificationManager.info("Info message", "", 3000, null, null, cName);
    case "success":
      return NotificationManager.success(
        title,
        msg,
        3000,
        null,
        null,
        cName
      );
    case "warning":
      return NotificationManager.warning(
        title,
        msg,
        3000,
        null,
        null,
        cName
      );
    case "error":
      return NotificationManager.error(
        msg,
        title,
        6000,
        null,
        cName
      )
    default:
      NotificationManager.info("Info message");
      break;
  }
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export const generateMsg = (log, selectedBooking) => {
  const status = {
    new: "Pending Payment",
    pending: "Pending",
    accepted: "Accepted",
    ontheway: "On The Way",
    active: "Session Started",
    completed: "Completed",
    rated: "Booking Rated",
    canceled: "Booking Canceled",
    rejected: "Booking Rejected",
  }
  let msg = ''
  switch (log.title) {
    case 'newRequest':
      msg = capitalize(log.createdBy?.role) + ` ( ${log.createdBy?.email} ) ${log.createdBy?.role === 'superAdmin' ? 'created' : 'booked'} appointment`
      break;
    case 'statusChange':
      msg = capitalize(log.createdBy?.role) + ` ( ${log.createdBy?.email} ) changed status from ` + status[log.prevStatus] + ' to ' + status[log.currentStatus]
      break;
    case 'viewRequest':
      msg = capitalize(log.createdBy?.role) + ` ( ${log.createdBy?.email} ) viewed request`
      break;
    case 'reschedule':
      msg = capitalize(log.createdBy?.role) + ` ( ${log.createdBy?.email} ) recheduled appointment from ` + moment(log?.date?.prev).format("DD/MM/YYYY hh:mm A") + ' to ' + moment(log?.date?.current).format("DD/MM/YYYY hh:mm A")
      break;
    case 'reassigned':
      msg = capitalize(log.createdBy?.role) + ` ( ${log.createdBy?.email} ) reassigned appointment from ` + (log?.provider?.prev?.en || log?.provider?.prev) + ' to ' + (log?.provider?.current?.en || log?.provider?.current)
      break;
    case 'assigned':
      msg = capitalize(log.createdBy?.role) + ` ( ${log.createdBy?.email} ) assigned appointment for ` + (log?.provider?.current?.en || log?.provider?.current)
      break;
    case 'delayed':
      msg = capitalize(log.createdBy?.role) + ` ( ${log.createdBy?.email} ) Reported delay`
      break;
    default:
      break;
  }
  return msg
}
export const UpdateUser = async (params) => {
  params = JSON.parse(JSON.stringify({ ...params, id: params?.uid }));
  if (params) {
    delete params?.toggle;
  }
  let idTokenResult = await auth().currentUser?.getIdTokenResult();
  try {
    await Axios.post('https://z754p4rjsf4cz6kz4vqjyvz3li0hktoj.lambda-url.me-south-1.on.aws/',
      JSON.stringify(params),
      {
        headers: {
          authorizer: idTokenResult?.token,
          USER_UID: params?.uid
        }

      })

    return { success: true }
  } catch (error) {
    if (error?.message === "Request failed with status code 401") {
      return { error: { code: 401, message: 'You are not Authorized!' } }
    }
    return { error: { code: 401, message: 'Unknown error,Please contact us' } }
  }

}
export function checkTimeRange() {
  let start = moment().startOf('day')
  let end = moment().startOf('day').hours(9).minutes(0).seconds(0);
  let now = moment();
  let isBetween = now.isBetween(start, end);
  return isBetween
}

export async function SearchUsers(searchTerm) {
  
  let idTokenResult = await auth().currentUser?.getIdTokenResult();
  let post = {
    search: (searchTerm)?.toLowerCase()
  }


  // let results = await Axios.post(`https://metadoc.azurewebsites.net/api/SearchUsers?code=sMqwJ68tVKhoqTsKOnWfG88OO2TGhjVKdNzQrXA3I6lHAzFuQKj2ag==`, {
  let results = await Axios.post(`https://cv3c4bygjbflhfarty4gt4tuoa0lyhbb.lambda-url.me-south-1.on.aws/`, JSON.stringify(post), {
    headers: {
      authorizer: idTokenResult?.token,
      USER_UID: auth().currentUser.uid
    },
  })
  return results.data

}

// export const checkUserExisted = async (e) => {
//   let searchQuery = Number(e)
//   searchQuery = String(searchQuery).replace(/\s/g, '');
//   let existed = false

//   let userRef = await firestore().collection('customers')
//     .where('phoneNumber', '==', ("+" + String(searchQuery)))
//     .get()

//   let asyncOptionsOnline = userRef?.docs?.map(res => {
//     return {
//       ...res.data(),
//       key: res.id,
//       number: res.data().phoneNumber
//     }
//   }).filter(rec => rec)
//   if (asyncOptionsOnline?.length) {
//     existed = asyncOptionsOnline?.[0]
//   }

//   return existed
// }
export const getAddressParams = (data, res) => {
  let { area } = data
  if (area) {
    return Axios.post(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${area}&fields=formatted_address,name&strictbounds&key=AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc`).then(results => {
      return (results.data)
    })
  }
  return false

}
export const getAddressDetails = (response, latLng) => {
  let address = response?.address_components?.reduce((value, currentValue) => {
    if (currentValue.types.includes("neighborhood")) {
      value.area = currentValue.short_name
    }
    if (!value.area && currentValue.types.includes("sublocality")) {
      value.area = currentValue.short_name
    }
    if (currentValue.types.includes("route")) {
      value.street = currentValue.short_name
    }
    if (currentValue.types.includes("locality") || currentValue.types.includes("administrative_area_level_1")) {
      value.emirate = currentValue.short_name
    }
    value.address = response.formatted_address;
    value.latLng = latLng
    return value
  }, {})
  return address

}
export const getCityFromAddress = async (latLng) => {
  try {
    let response = await ReverseGeocode(latLng)
    const addressComponents = response?.results?.[0]?.address_components;
    console.log();
    for (let i = 0; i < addressComponents.length; i++) {
      const types = addressComponents[i].types;
      if (types.includes("locality")) {
        return addressComponents[i].long_name;
      }
    }
    return null;
  } catch (error) {
    console.log('error', error);

  }
};
export const checkAddressUrl = (link) => {
  let url = false;
  let query = false;
  try {
    url = new URL(link)
  }
  catch (e) {
    url = false
  }

  // eslint-disable-next-line
  var expressionLatLng = /\@[-?\d\.]*\,([-?\d\.]*)/gi;
  // eslint-disable-next-line
  var expression2LatLng = /ll=[-?\d\.]*\,([-?\d\.]*)/gi;

  var stringUrl = String(url);
  var regexlatLng = new RegExp(expressionLatLng);
  var regexlatLng2 = new RegExp(expression2LatLng);
  if (stringUrl.match(regexlatLng)) {
    query = stringUrl.match(regexlatLng)?.[0].replace('@', '');
  } else if (stringUrl.match(regexlatLng2)) {
    query = stringUrl.match(regexlatLng2)?.[0].replace('ll', '');
  } else {
    query = url?.searchParams?.get('q')
  }
  return { url: url, query }

}

export const getAvailableTimings = (async (data, context) => {
  let { variationsKeys, activeProvidersSnap } = data;
  let activeProviders = await Promise.all(activeProvidersSnap?.map(async rec => {

    let candidateProvider = await variationsKeys.reduce(async (val, currentVal) => {
      let variationSnap = await firestore().collection('providers').doc(rec.id).collection('variations').doc(currentVal).get();
      let currentArray = (await val) || [];
      if (variationSnap.exists) {
        currentArray.push({ ...variationSnap.data(), provider: rec.id, slots: GetDateSlots({ ...variationSnap.data(), key: currentVal }) })
      }
      return currentArray
    }, []);

    if (candidateProvider.length === variationsKeys.length) {
      return ({ ...rec, variationData: candidateProvider })
    }
  }))

  let dates = (activeProviders || []).reduce((val, currentVal) => {
    // eslint-disable-next-line 
    ((currentVal && currentVal.variationData) ? currentVal.variationData : []).map(rec => {
      let { slots, provider } = rec;
      if (val.length === 0) {
        val = val.concat(slots.map(rec => { return ({ ...rec, candidates: [provider] }) }))
        return false
      }
      slots.map(timing => {
        let index = val.findIndex(a => a.date === timing.date);
        if (index === -1) {
          val.push({ ...timing, candidates: [provider] })
        } else {
          let existing = val[index];
          existing.value = existing.value.concat(timing.value);
          existing.candidates = [...new Set([...existing.candidates, provider])]
          val[index] = existing
        }
        return false
      })
    })
    return val
  }, [])

  let post = { dates: dates }
  return JSON.stringify(post)
})
const GetDateSlots = (data) => {
  let { timings, key } = data;
  let dates = [];
  let step = moment();
  let weekDays = [
    { key: 'Sunday', title: 'Sun' },
    { key: 'Monday', title: 'Mon' },
    { key: 'Tuesday', title: 'Tue' },
    { key: 'Wednesday', title: 'Wed' },
    { key: 'Thursday', title: 'Thu' },
    { key: 'Friday', title: 'Fri' },
    { key: 'Saturday', title: 'Sat' },
  ];

  for (var i = 0; i < 7; i++) {
    let weekDay = weekDays[step.day()]
    let dayTimming = timings && timings[weekDay.key] ? timings[weekDay.key] : {};
    let { isOpen } = dayTimming || {}
    let value = [];
    (dayTimming && dayTimming.timings ? dayTimming.timings : []).map(rec => {
      let start = rec.startTime
      let end = rec.endTime
      value.push({
        start, end, key
      })
      // eslint-disable-next-line 
      return
    })

    dates.push({
      id: i,
      date: step.format('DD'),
      weekDay: weekDay.title,
      dateTimestamp: step.valueOf(),
      value: isOpen ? value : []
    })
    step.add(1, 'day')
  }
  return dates
}

export const checkIfDisabled = (data, required) => {
  let errors = {};
  let formIsValid = false;

  if (!data?.updated) {
    return true
  }
  required.forEach(w => {
    var isArr = data[w] instanceof Array;
    switch (typeof data[w]) {
      case 'string':
        if (!data[w]) {
          formIsValid = true;
          errors[w] = "Please fill the required fields";
        }
        break;
      case 'object':
        if (isArr ? !data[w]?.length : (data[w]?.en || data[w]?.en === '' ? !data[w]?.en : !Object.keys(data[w]).length)) {

          formIsValid = true;
          errors[w] = "Please fill the required fields";
        }
        break;

      default:
        if (!data[w]) {
          formIsValid = true;
          errors[w] = "Please fill the required fields";
        }
        break;
    }
  });
  return formIsValid;
}

export const calcTotalCost = (items, promo, addons, bookingReceipt) => {
  let addonsPrice = Object.values(addons || {}).reduce((val, currentVal) => {
    val = val + (Number(currentVal.price) * Number(currentVal.count))
    return val
  }, 0)
  let price = Object.values(items).reduce((val, currentVal) => {
    val = val + (Number(currentVal.price?.total) * Number(currentVal.count))
    return val
  }, 0)
  let topup = Object.values(items).reduce((val, currentVal) => {
    val = val + (Number(currentVal.price?.topUp) * Number(currentVal.count))
    return val
  }, 0)
  let totalPrice = price + Number(addonsPrice || 0)
  let sub = totalPrice

  if (bookingReceipt?.serviceFees) {
    totalPrice += (bookingReceipt?.serviceFees?.value * 1)
  }

  let receipt = {
    ...bookingReceipt,
    sub: sub,
    totalTopup: Number(topup || 0),
    addonsPrice: addonsPrice,
    itemsPrice: price,
    tax: totalPrice * 0.05,
    total: totalPrice,
  }
  if (receipt?.discount && !promo) {
    totalPrice = totalPrice - Number(receipt?.discount)
    receipt.total = totalPrice
    receipt.tax = totalPrice * 0.05
  }

  if (promo) {
    let { type, val } = promo;
    let discount;
    switch (type) {
      case 'percent':
        discount = totalPrice * val / 100
        totalPrice = totalPrice - discount
        receipt.total = totalPrice
        receipt.discount = discount
        receipt.code = 'special_offer'
        receipt.tax = totalPrice * 0.05
        break;
      default:
        totalPrice = totalPrice - (val > totalPrice ? totalPrice : val)
        receipt.total = totalPrice
        receipt.discount = (val > totalPrice ? totalPrice : val)
        receipt.code = 'special_offer'
        receipt.tax = totalPrice * 0.05
    }
  }
  return receipt
}
export const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
export const formatAddress = (rec) => {
  const sorter = {
    "type": 1,
    "flat": 2,
    "villa": 3,
    "building": 4,
    "street": 5,
    "area": 6,
    "emirate": 7,
    "additional_directions": 8,
  };

  let tmp = [];
  Object.keys(rec).forEach(function (key) {
    let value = rec[key];
    let index = sorter[key.toLowerCase()];

    tmp[index] = {
      key: key,
      value: value?.en || value
    };
  });
  let orderedData = {};
  tmp.forEach(function (obj) {
    orderedData[obj.key] = obj.value;
  });
  let formatted_address = Object.values(orderedData).filter(r => r).join(' - ')

  return formatted_address
}

export const CheckLocationIsServed = async (servingAreas, currenrLocation) => {
  let places_ids = servingAreas.reduce((val, currentVal) => {
    // val = val.concat(currentVal.areas_list)
    let list = currentVal.areas_list.map(r => {
      return { cluster: currentVal.id, ...r }
    })
    val = val.concat(list)

    return val
  }, [])
  let polygons = await Promise.all(places_ids.map(async r => {
    try {
      let snap = await firestore().collection('locations').doc(r?.id).get()
      // let results = axios.get(`https://nominatim.openstreetmap.org/details.php?place_id=${r?.id}&polygon_geojson=1&format=json`)
      return { ...snap?.data(), cluster: r.cluster }
    } catch (error) {
      console.log("errorerror", error);
      // let snap = await firestore().collection('locations').where('place_id', '==', r?.id).get()
      // return snap?.data()
    }

  }))

  let place = polygons.find(r => {
    let existed;
    switch (r.geojson?.type) {
      case "MultiPolygon":
        existed = Object.keys(r.geojson.coordinates).reduce((val, current) => {
          let existed = isPointInPolygon(currenrLocation, r.geojson.coordinates[current])
          if (existed) {
            val = true
          }
          return val
        }, false)
        return existed
      default:
        let polygon = r.geojson?.coordinates
        existed = isPointInPolygon(currenrLocation, polygon)
        return existed
    }
  })

  return { place_id: place?.place_id, cluster: place?.cluster }

}

export const submitOrder = async (booking, newStatus, id, user, label) => {
  let oldBooking = JSON.parse(JSON.stringify(booking));

  booking.updated = Date.now()
  booking.status = newStatus
  booking[newStatus] = Date.now()
  booking.updatedBy = user.uid

  delete booking.delayedOntheWay
  delete booking.delayedActive
  delete booking.delayedCompleted
  delete booking.viewOrder

  switch (newStatus) {
    case 'accepted':
      let sfDocRef = firestore().collection('orders').doc(id);
      return firestore().runTransaction(async (transaction) => {
        const sfDoc = await transaction.get(sfDocRef);
        if (!sfDoc.exists) {
          // eslint-disable-next-line
          throw "Document does not exist!";
        }
        if (sfDoc.data()?.provider?.id && (sfDoc.data()?.provider?.id !== (booking?.provider?.id || '--'))) {
          // eslint-disable-next-line
          throw "Another service provider has accepted this request!";
        }
        transaction.update(sfDocRef, booking);
      }).then(() => {
        createNotification("success", "Success", label)
        return { booking }
      }).catch((err) => {
        console.error(err);
        createNotification("error", err, 'Error')
        return { booking: oldBooking }
      });

    default:
      await firestore().collection('orders').doc(id).set(booking, { merge: true }).then(() => {
        createNotification("success", "Success", label)
      })
      break;
  }

  return { booking }
}

export const ListServiceVariations = (order) => {

  let { items, addons, service } = order;
  let services = Object.values(items).map(item => { return { name: item.name && (item.name.en || item.name), qty: item.count, price: item.price.total, service: service.name && service.name.en } })
  if (addons) {
    let addedAddons = Object.values(addons).map(item => { return { name: item.name && item.name.en, qty: item.count, price: item.price, service: 'Addons' } })
    services = services.concat(addedAddons)
  }
  return services
}

export const checkPasswordValidity = (value) => {
  let errors = []
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    errors.push("Password must not contain Whitespaces.");
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(value)) {
    errors.push("Password must have at least one Uppercase Character.");
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(value)) {
    errors.push("Password must have at least one Lowercase Character.");
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(value)) {
    errors.push("Password must contain at least one Digit.");
  }
  // eslint-disable-next-line
  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
  if (!isContainsSymbol.test(value)) {
    errors.push("Password must contain at least one Special Symbol.");
  }

  const isValidLength = /^.{8,32}$/;
  if (!isValidLength.test(value)) {
    errors.push("Password must be 8-32 Characters Long.");
  }

  return errors?.length ? errors : null;
}
export const sortTimings = (timings) => {
  const sorter = {
    "saturday": 1,
    "sunday": 2,
    "monday": 3,
    "tuesday": 4,
    "wednesday": 5,
    "thursday": 6,
    "friday": 7,
  };

  let tmp = [];
  Object.keys(timings).forEach(function (key) {
    let value = timings[key];
    let index = sorter[key.toLowerCase()];
    tmp[index] = {
      key: key,
      value: value
    };
  });

  let orderedData = {};
  tmp.forEach(function (obj) {
    orderedData[obj.key] = obj.value;
  });
  return orderedData
}

export const exportCSV = (data, user) => {

  let prefix = "data:text/csv;charset=utf-8,";
  let header = ""
  if (['superAdmin', 'accountant'].includes(user.role)) {
    header = "Order Ref., Invoice Number, Name of Customer, Customer Number, Area, Service,Variations, Date of Service, Client, Service Provider, Subtotal Fees, Discount, Discount Code, Total Service Fees,  Service Fees, Addons Fees, SP Total Fees, SP Service Fees, SP Addons Fees, MD comission, SP Balance , Order Status, Customer Rate, Payment Type, Notes, Team Notes, type";
  } else if (user.role === 'admin') {
    header = "Order Ref., Invoice Number, Name of Customer, Area, Service,Variations, Date of Service, Client, Subtotal Fees, Discount, Discount Code, Total Service Fees, Service Fees, Addons Fees, SP Total Fees, SP Service Fees, SP Addons Fees, MD comission, SP Balance ,  Order Status, Customer Rate, Payment Type, type";
  } else if (user.role === 'concierge') {
    header = "Order Ref., Invoice Number, Name of Customer, Area, Service,Variations, Date of Service, Client, Subtotal Fees, Discount, Discount Code, Total Service Fees, Service Fees, Addons Fees,  Order Status, Customer Rate, Payment Type, Notes, type";
  }
  let csvContent = header + "\r\n";
  data.map(item => {
    let variations = JSON.stringify((Object.values(item?.items || {}))?.map(i => i.name?.en).join('-'));

    if (['superAdmin', 'accountant'].includes(user.role)) {
      csvContent += `${item?.orderNumber || '--'}, ${item?.invoiceNumber || '--'},${item?.contact?.name || '--'},${item?.contact?.phoneNumber || '--'},${item?.location?.area?.en || item?.location?.area || '--'},${item?.service?.name?.en || '--'},${variations || '--'}, ${item?.type === 'settlement' ? moment(item?.start).format("DD/MM/YYYY hh:mmA") : moment(item?.bookingDate?.date).format("DD/MM/YYYY hh:mmA")},${item?.brand?.name?.en || item?.brand?.name || 'Metadoc'},${item?.provider?.name?.en || item?.provider?.name || '--'},${calculateSOA(item)?.sub?.toFixed(2)},${Number(item?.receipt?.discount || 0)?.toFixed(2)},${item?.receipt?.code || '--'},${calculateSOA(item)?.total?.toFixed(2)},${(calculateSOA(item)?.total?.toFixed(2) - calculateSOA(item)?.addonsPrice?.toFixed(2)).toFixed(2)},${calculateSOA(item)?.addonsPrice?.toFixed(2)},${calculateSOA(item)?.providerFees?.toFixed(2)},${(item?.receipt?.providerFees || 0)?.toFixed(2)},${(calculateSOA(item)?.addonsPrice * 0.75)?.toFixed(2)},${calculateSOA(item)?.metadocCommission?.toFixed(2)},${item?.type === 'settlement' ? (item?.total)?.toFixed(2) : calculateSOA(item)?.totalAmount?.toFixed(2)},${(item?.status || '--')?.toUpperCase()},${(item?.rate || '--')},${getPaymentType(item)},${item?.notes?.replace(/[^a-zA-Z ]/g, "") || '--'},${item?.teamNotes?.replace(/[^a-zA-Z ]/g, "") || '--'},${item?.type || 'online'}\r\n`;
    } else if (user.role === 'admin') {
      csvContent += `${item?.orderNumber || '--'}, ${item?.invoiceNumber || '--'},${item?.contact?.name || '--'},${item?.location?.area?.en || item?.location?.area || '--'},${item?.service?.name?.en || '--'},${variations || '--'}, ${item?.type === 'settlement' ? moment(item?.start).format("DD/MM/YYYY hh:mmA") : moment(item?.bookingDate?.date).format("DD/MM/YYYY hh:mmA")},${item?.brand?.name?.en || item?.brand?.name || 'Metadoc'},${calculateSOA(item)?.sub?.toFixed(2)},${Number(item?.receipt?.discount || 0)?.toFixed(2)},${item?.receipt?.code || '--'},${calculateSOA(item)?.total?.toFixed(2)},${(calculateSOA(item)?.total?.toFixed(2) - calculateSOA(item)?.addonsPrice?.toFixed(2)).toFixed(2)},${calculateSOA(item)?.addonsPrice?.toFixed(2)},${calculateSOA(item)?.providerFees?.toFixed(2)},${(item?.receipt?.providerFees || 0)?.toFixed(2)},${(calculateSOA(item)?.addonsPrice * 0.75)?.toFixed(2)},${calculateSOA(item)?.metadocCommission?.toFixed(2)},${item?.type === 'settlement' ? (item?.total)?.toFixed(2) : calculateSOA(item)?.totalAmount?.toFixed(2)},${(item?.status || '--')?.toUpperCase()},${(item?.rate || '--')},${getPaymentType(item)},${item?.notes?.replace(/[^a-zA-Z ]/g, "") || '--'},${item?.type || 'online'}\r\n`;
    } else if (user.role === 'concierge') {
      csvContent += `${item?.orderNumber || '--'}, ${item?.invoiceNumber || '--'},${item?.contact?.name || '--'},${item?.location?.area?.en || item?.location?.area || '--'},${item?.service?.name?.en || '--'},${variations || '--'}, ${item?.type === 'settlement' ? moment(item?.start).format("DD/MM/YYYY hh:mmA") : moment(item?.bookingDate?.date).format("DD/MM/YYYY hh:mmA")},${item?.brand?.name?.en || item?.brand?.name || 'Metadoc'},${calculateSOA(item)?.sub?.toFixed(2)},${Number(item?.receipt?.discount || 0)?.toFixed(2)},${item?.receipt?.code || '--'},${calculateSOA(item)?.total?.toFixed(2)},${(calculateSOA(item)?.total?.toFixed(2) - calculateSOA(item)?.addonsPrice?.toFixed(2)).toFixed(2)},${calculateSOA(item)?.addonsPrice?.toFixed(2)},${(item?.status || '--')?.toUpperCase()},${(item?.rate || '--')},${getPaymentType(item)},${item?.type || 'online'}\r\n`;
    }
    return false
  })
  var encodedUri = prefix + encodeURIComponent(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "file.csv");
  document.body.appendChild(link);
  link.click();
}
export const getPaymentType = (booking) => {
  if (booking?.type === 'settlement') {
    return 'Settlement'
  }
  if (booking.paymentStatus) {
    switch (booking.paymentStatus) {
      case 'paid':
        return 'Online Payment'
      case 'partiallyPaid':
        return 'Online/Cash Payment'
      case 'notPaid':
        return 'Cash Payment'
      default:
        break;
    }
  } else {
    if (booking.paid) {
      return 'Online Payment'
    } else {
      return 'Cash Payment'

    }
  }

}
export async function createDynamicLink(uid, value) {

  let logo = 'https://firebasestorage.googleapis.com/v0/b/metadoc-app.appspot.com/o/md%20bannners.jpg?alt=media&token=56b67f85-2b00-42a7-aaa8-a63f6890a627'
  let link = (`https://metadoc.page.link/?link=${encodeURIComponent(`https://app.metadocapp.com/ambassador?ambassadorUID=${uid}`)}&apn=com.metadocapp&isi=6443656840&ibi=org.metadocapp&utm_medium=referral&st=Congratulations 🎉&sd=Here’s AED ${value} to use on your booking with Metadoc App at-home services!&si=${encodeURIComponent(logo)}`)

  let results = await axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAlzNfaoQBisO29y_22TugrE0aUb2BNx8o', {
    'longDynamicLink': link,
    "suffix": {
      "option": "SHORT"
    },

  })

  return results?.data?.shortLink
}


function formatDateYYYYMMDD(date) {
  let dateString = date.toLocaleDateString('en-GB');
  let year = dateString.substring(6, 10);
  let month = dateString.substring(3, 5);
  let day = dateString.substring(0, 2);
  return `${year}-${month}-${day}`;
}

export function getWeeksArray(month, year) {
  let tempDate = new Date(year, (month - 1), 1);
  let week = [];
  let weeksArray = []
  while (tempDate.getMonth() === (month - 1)) {
    week.push(formatDateYYYYMMDD(tempDate));
    if (moment(formatDateYYYYMMDD(tempDate)).format("dddd") === 'Tuesday') {
      weeksArray.push(week)
      week = []
    }
    tempDate.setDate(tempDate.getDate() + 1);
  }
  if (week.length) {
    weeksArray.push(week)
  }
  return weeksArray;
}

export const calculateSOA = (booking) => {
  let totalAmount = 0
  let post = {}
  // if (['completed'].includes(booking.status)) {
  post.sub = Number(booking?.receipt?.sub || 0)
  post.discount = Number(booking?.receipt?.discount || 0) / (Number(booking?.receipt?.total || 1) + Number(booking?.receipt?.discount || 0))
  post.total = Number(booking?.receipt?.total || 0)
  post.paid = Number(booking?.receipt?.paid || 0)
  post.outstanding = Number(booking?.receipt?.outstanding || 0)
  post.addonsPrice = Number(booking?.receipt?.addonsPrice || 0) * ((1 - post.discount) || 1)
  post.providerFees = Number(booking?.receipt?.providerFees || 0) + (post.addonsPrice || 0) - ((post.addonsPrice || 0) * 0.25)
  post.metadocCommission = Number(post.total || 0) - Number(post.providerFees)
  post.addonsComission = Number(booking?.receipt?.addonsPrice || 0) * 0.25
  // }
  if (booking?.paymentStatus) {
    switch (booking?.paymentStatus) {
      case 'paid':
        totalAmount = Number(post.total - post.metadocCommission)
        break;
      case 'partiallyPaid':
        totalAmount = Number(post.providerFees - post.outstanding)
        break;
      case 'notPaid':
        totalAmount = Number(post.providerFees - post.total)
        break;
      default:
        break;
    }
  } else {
    if (booking.paid) {
      totalAmount = Number(post.total - post.metadocCommission)
    } else {
      totalAmount = Number(post.providerFees - post.total)
    }
  }
  post.totalAmount = totalAmount
  return post
}

export const copyMetadocVariations = async (serviceId) => {
  let variationSnap = await firestore().collection('partners').doc('IlDSRL3CAgKT7XDF5fXp').collection('variations').where('service_id', '==', serviceId).get();
  let variations = variationSnap?.docs?.reduce((val, currentVal) => {
    val[currentVal.id] = { key: currentVal.id, ...currentVal.data(), updated: true }
    return val
  }, {})
  return variations
}

export const ReverseGeocode = (center) => {
  return new Promise((resolve, reject) => {
    const google = window.google
    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({
        location: center,
        region: 'ae',
        language: 'en'
      }).then(async (response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
  })
}
export function getFirstCharacters(sentence) {
  const words = sentence?.split(' '); // Split the sentence into words
  const firstCharacters = words?.map(word => word.charAt(0)); // Extract the first character from each word
  return firstCharacters?.join(''); // Concatenate the first characters
}