import React, { useEffect, useState } from "react";
import {
    Row,
    Input,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import Timings from "./timings";
import { useDispatch, useSelector } from "react-redux";
import { firestore } from "../../helpers/Firebase";
import { UpdateItem } from "../../redux/activeItem/actions";
import { List, arrayMove } from 'react-movable';
import { copyMetadocVariations } from "../../helpers/Utils";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";


const Service = ({ section }) => {
    const { services } = useSelector(state => state.settings)
    const { activeItem } = useSelector(state => state.activeItem)
    const { user } = useSelector(state => state.authUser)
    const [sortedServices, setSortedServices] = useState(activeItem?.general?.services?.filter(rec => services.find(service => service.id === rec && service.active)) || [])
    const [variations, setVariations] = useState(activeItem?.variations || {})
    const [updatedVariations, UpdateVariations] = useState({})
    const dispatch = useDispatch()

    console.log('activeItem?.general', activeItem?.general);

    const [selectedService, setSelectedService] = useState(false)
    const [selectedVariation, setSelectedVariation] = useState(false)



    const selectService = async (serviceData) => {
        let post = {};
        let { id } = serviceData;
        let tapsData = ["variations"]
        let filteredVariations = variations && Object.values(variations)?.filter(rec => (rec.service_id === id) && !rec.deleted)?.sort((a, b) => a.index - b.index).reduce((val, currentVal) => {
            val[currentVal.id] = { ...currentVal, id: currentVal.id, key: currentVal.id, value: currentVal?.name, label: currentVal?.name }
            return val
        }, {})
        await Promise.all(tapsData.map(async rec => {
            let snap = await firestore().collection('services').doc(id).collection(rec).get()
            let data = snap.docs.reduce((val, currentVal) => {
                if (!filteredVariations[currentVal.id]) {
                    val[currentVal.id] = { ...currentVal.data(), id: currentVal.id, key: currentVal.id, value: currentVal.data()?.name, label: currentVal.data().name }
                }
                return val
            }, {})
            post[rec] = data
            serviceData.allVariations = data
            serviceData.variations = filteredVariations
            UpdateVariations(filteredVariations)
            setSelectedService(serviceData)
            return data
        }))
    }

    const updateService = (val, service_id) => {
        if (val) {
            let services = sortedServices || []
            if (!services?.includes(service_id)) {
                services.push(service_id)
            }
            setSortedServices(services)

            dispatch(UpdateItem({ key: 'variations', val: { ...variations, ...val } }))
            setVariations({ ...variations, ...val })
            setSelectedService(false)
        }
    }

    useEffect(() => {
        activeItem.general.services = sortedServices
        activeItem.general.updated = true

        dispatch(UpdateItem({ key: 'general', val: activeItem.general }))
        // eslint-disable-next-line
    }, [sortedServices, activeItem.general])

    const handleVariationPriceChange = (inputKey, value, itemPricing) => {
        let pricing = JSON.parse(JSON.stringify(itemPricing)) || {}
        pricing[inputKey] = value
        if (pricing?.discountedPrice) {
            pricing.actualPrice = String(Number(pricing.val) + Number(pricing?.topUp || 0))
        } else {
            delete pricing.discountedPrice
            delete pricing.actualPrice
        }
        pricing.total = pricing?.discountedPrice ? String(Number(pricing?.discountedPrice)) : String(Number(pricing?.val) + Number(pricing?.topUp || 0))
        return pricing
    }
    return (
        <Card className="rounded">
            <CardBody>
                <Row>
                    <Colxx xxs="12" className="mb-2">
                        <div className="mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                            <h4 className="mb-0">Services</h4>
                        </div>
                        {user.role === 'superAdmin' ? <Select
                            components={{ Input: CustomSelectInput }}
                            className="basic-multi-select mb-2"
                            value={''}
                            placeholder={'Select services provided'}
                            // eslint-disable-next-line
                            options={services?.filter(r => r.active && !r.deactivated && !sortedServices?.includes(r.id)).map(r => {
                                return ({ ...r, key: r.id, value: r?.name?.en, label: r?.name?.en })
                            })}
                            onChange={val => {
                                selectService(val)
                                // UpdatedProvider({ ...updatedProvider, [item.key]: val.map(rec => rec.key) })
                            }}
                        />
                            : ''}
                    </Colxx>
                </Row>
                <Separator className="mb-5" />
                <Row>
                    <Colxx xxs="12" className="mb-2">
                        <>
                            <Row className="d-flex align-items-center mt-4">

                                <Colxx xxs="1" />
                                <Colxx xxs="2" style={{ fontWeight: '700' }}>Name</Colxx>
                                {/* <Colxx xxs="2" style={{ fontWeight: '700' }}>Arabic Name</Colxx> */}
                                <Colxx xxs="4" style={{ fontWeight: '700' }}>Variation Prices</Colxx>
                                <Colxx xxs="3" />
                            </Row>
                            <List
                                values={sortedServices}
                                onChange={({ oldIndex, newIndex }) => {
                                    setSortedServices(arrayMove(sortedServices, oldIndex, newIndex))
                                }}
                                renderList={({ children, props }) => <ul className="p-0" {...props}>{children}</ul>}

                                renderItem={({ value, props }) => {

                                    let item = services.find(rec => rec.id === value)
                                    let sorted = Object.values(variations)?.filter(r => r.service_id === value && !r.deleted)?.sort((a, b) => a.index - b.index)
                                    props.style = { ...props.style, borderBottom: '1px solid #f3f3f3', listStyle: 'none' }
                                    return (<div {...props} className="mt-3 pb-3" >
                                        <Row className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                            <Colxx xxs="1">
                                                <div>
                                                    <i className="simple-icon-cursor-move" />
                                                </div>
                                            </Colxx>
                                            <Colxx xxs="2">
                                                <span>{item?.name?.en}</span>
                                            </Colxx>
                                            <Colxx xxs="2">
                                                <span>{item?.name?.ar}</span>
                                            </Colxx>
                                            <Colxx xxs="4">{sorted?.map(r => {
                                                return <div key={r.id}>{r?.name?.en + " " + (section === 'provider' ? ((Number(r?.providerPrice || 0))).toFixed(2) + ' AED' : ((Number(r?.pricing?.total || r?.price || 0))).toFixed(2) + ' AED')}</div> || ''
                                            })}
                                            </Colxx>
                                            <Colxx xxs="3" >
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    outline
                                                    className="float-right"
                                                    onClick={() => selectService(item)}>
                                                    Edit
                                                </Button>
                                                {user.role === 'superAdmin' ? <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="float-right mr-2"
                                                    outline
                                                    onClick={() => {
                                                        let newData = JSON.parse(JSON.stringify(variations))
                                                        newData = newData && Object.values(newData)?.reduce((val, currentVal) => {
                                                            val[currentVal.id] = currentVal
                                                            if (item.id === currentVal.service_id) {
                                                                val[currentVal.id].deleted = true
                                                                val[currentVal.id].updated = true
                                                            }
                                                            return val
                                                        }, {})
                                                        dispatch(UpdateItem({ key: 'variations', val: newData || {} }))
                                                        setVariations(newData)
                                                        // eslint-disable-next-line
                                                        let services = sortedServices.filter(rec => rec !== item.id)
                                                        setSortedServices(services || [])
                                                        activeItem.general.services = services.map(rec => rec.id) || []
                                                        activeItem.general.updated = true
                                                        dispatch(UpdateItem({ key: 'general', val: activeItem.general }))
                                                    }}>
                                                    Remove
                                                </Button> : ''}

                                            </Colxx>
                                        </Row>
                                    </div>)
                                }}
                            />
                        </>
                    </Colxx>
                </Row>
            </CardBody>
            {selectedService && <Modal
                size='lg'
                isOpen={selectedService}>
                <div className="modal-header d-flex flex-row justify-content-between align-items-center" >
                    <h5 className="font-weight-bold m-0 mb-2">{selectedService?.name?.en}</h5>
                    {user.role === 'superAdmin' && <Button onClick={async () => {
                        let metadocvariations = await copyMetadocVariations(selectedService.id)
                        UpdateVariations(metadocvariations)
                    }}>
                        Copy Metadoc
                    </Button>}
                </div>
                <ModalBody>
                    <Row>
                        <Colxx xxs="12" className="mb-4">
                            {user.role === 'superAdmin' ? <>
                                <div className="mb-2" >{'Select provided service variations'}</div>
                                <Select
                                    components={{ Input: CustomSelectInput }}
                                    className="basic-multi-select multiseleczindex mb-2"
                                    placeholder={'Select variations'}
                                    value={''}
                                    options={[{ value: 'All', label: 'All', key: 'all' }, ...(selectedService?.allVariations && Object.values(selectedService?.allVariations || {}).map(rec => { return ({ ...rec, value: rec?.name?.en, label: rec?.name?.en, key: rec.id }) })) || []]}
                                    onChange={val => {
                                        if (val.key === 'all') {
                                            let newData = JSON.parse(JSON.stringify(updatedVariations))
                                            let serviceData = JSON.parse(JSON.stringify(selectedService))

                                            selectedService?.allVariations && Object.values(selectedService?.allVariations || {}).map(rec => {
                                                newData[rec.key] = rec
                                                newData[rec.key].updated = true
                                                newData[rec.key].pricing = {
                                                    val: rec.price,
                                                    total: rec.price
                                                }
                                                delete serviceData.allVariations[rec.key]
                                                setSelectedService(serviceData)
                                                return UpdateVariations(newData)
                                            })
                                        } else {
                                            let newData = JSON.parse(JSON.stringify(updatedVariations))
                                            newData[val.key] = val
                                            newData[val.key].updated = true
                                            newData[val.key].pricing = {
                                                val: val.price,
                                                total: val.price
                                            }
                                            UpdateVariations(newData)

                                            let serviceData = JSON.parse(JSON.stringify(selectedService))
                                            delete serviceData.allVariations[val.key]
                                            setSelectedService(serviceData)
                                        }

                                    }}
                                />
                            </> : ''}
                            {

                                updatedVariations && Object.values(updatedVariations)?.length ?
                                    <>
                                        <Row className="d-flex align-items-center mt-4">

                                            <Colxx xxs="1">
                                            </Colxx>
                                            {activeItem?.general?.featured && <Colxx xxs={'1'} style={{ fontWeight: '700' }}>
                                                Offer
                                            </Colxx>}
                                            <Colxx xxs={section === 'provider' ? "4" : '2'} style={{ fontWeight: '700' }}>
                                                Variation Name
                                            </Colxx>
                                            {section !== 'provider' && <Colxx style={{ fontWeight: '700' }} xxs="3">
                                                {"Base Price"}
                                            </Colxx>
                                            }
                                            <Colxx xxs={section === 'provider' ? "4" : "2"} style={{ fontWeight: '700' }}>
                                                {section === 'provider' ? "Provider Price" : "Topup"}
                                            </Colxx>
                                            {section !== 'provider' && <Colxx style={{ fontWeight: '700' }} xxs="2">
                                                {"Discounted Price"}
                                            </Colxx>
                                            }
                                            {section !== 'provider' && <Colxx style={{ fontWeight: '700' }} xxs="2">
                                                {"Total Price"}
                                            </Colxx>
                                            }
                                            <Colxx xxs={section !== 'provider' && "1"} />
                                        </Row>
                                        <List
                                            values={Object.values(updatedVariations)?.filter(rec => (rec.service_id === selectedService.id) && !rec.deleted)?.sort((a, b) => a.index - b.index)}
                                            onChange={({ oldIndex, newIndex }) => {
                                                let sortedVariations = arrayMove(Object.values(updatedVariations)?.filter(rec => (rec.service_id === selectedService.id) && !rec.deleted), oldIndex, newIndex)
                                                let newVari = sortedVariations?.filter(rec => rec.service_id === selectedService.id)?.reduce((val, currentVal, index) => {

                                                    currentVal.updated = true
                                                    currentVal.index = index
                                                    val[currentVal.id] = currentVal
                                                    return val
                                                }, {})

                                                UpdateVariations(newVari)
                                            }}
                                            renderList={({ children, props }) => <ul className="p-0" {...props}>{children}</ul>}

                                            renderItem={({ value, props }) => {

                                                props.style = { ...props.style, borderBottom: '1px solid #f3f3f3', zIndex: 100000 }
                                                return (
                                                    <div {...props} className="mt-3 pb-3" >
                                                        <Row className="d-flex align-items-center">
                                                            <Colxx xxs="1">
                                                                <div>
                                                                    <i className="simple-icon-cursor-move" />
                                                                </div>
                                                            </Colxx>
                                                            {activeItem?.general?.featured && <Colxx xxs={'1'}>
                                                                <Switch
                                                                    className="custom-switch custom-switch-primary"
                                                                    checked={value?.isOffer}
                                                                    onChange={switchCheckedPrimary => {
                                                                        let newData = JSON.parse(JSON.stringify(updatedVariations))
                                                                        newData[value.id] = newData[value.id] || {}
                                                                        newData[value.id].service_id = selectedService.id
                                                                        newData[value.id].updated = true
                                                                        newData[value.id].isOffer = switchCheckedPrimary
                                                                        UpdateVariations(newData)
                                                                    }}
                                                                />
                                                            </Colxx>}
                                                            <Colxx xxs={section === 'provider' ? "4" : '2'}>
                                                                <div>{value?.name?.en}</div>
                                                            </Colxx>
                                                            {section !== 'provider' && <Colxx xxs="3">
                                                                <InputGroup>
                                                                    <Input className="customInput"
                                                                        disabled={user.role !== 'superAdmin'}
                                                                        value={value?.pricing?.val || value?.pricing?.val === '' ? value?.pricing?.val : updatedVariations[value.id].price}
                                                                        placeholder={"e.g. 200 AED"}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            let newData = JSON.parse(JSON.stringify(updatedVariations))

                                                                            newData[value.id] = newData[value.id] || {}
                                                                            newData[value.id].service_id = selectedService.id
                                                                            newData[value.id].updated = true
                                                                            let pricing = handleVariationPriceChange('val', e.target.value, value.pricing)
                                                                            newData[value.id].pricing = pricing
                                                                            UpdateVariations(newData)
                                                                        }}
                                                                    />
                                                                    <InputGroupAddon addonType="append">
                                                                        <InputGroupText>AED</InputGroupText>
                                                                        {updatedVariations[value.id].price !== value?.pricing?.val && (value?.pricing?.val || value?.pricing?.val === '') && user.role === 'superAdmin' ? <Button
                                                                            onClick={() => {

                                                                                let newData = JSON.parse(JSON.stringify(updatedVariations))

                                                                                newData[value.id] = newData[value.id] || {}
                                                                                newData[value.id].service_id = selectedService.id
                                                                                newData[value.id].updated = true
                                                                                let pricing = handleVariationPriceChange('val', newData[value.id].price, value.pricing)
                                                                                newData[value.id].pricing = pricing
                                                                                UpdateVariations(newData)

                                                                            }}
                                                                            outline color="primary" size="sm"><i className="iconsminds-repeat-3" /></Button>
                                                                            : ''}
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </Colxx>}
                                                            <Colxx xxs={section === 'provider' ? "4" : "2"}>
                                                                <InputGroup>
                                                                    <Input className="customInput"
                                                                        disabled={user.role !== 'superAdmin'}
                                                                        value={section === 'provider' ? (value?.providerPrice) : value?.pricing?.topUp}
                                                                        placeholder={"e.g. 200 AED"}
                                                                        type="number"
                                                                        onChange={(e) => {

                                                                            let newData = JSON.parse(JSON.stringify(updatedVariations))

                                                                            newData[value.id] = newData[value.id] || {}
                                                                            newData[value.id].service_id = selectedService.id
                                                                            newData[value.id].updated = true

                                                                            if (section === 'provider') {
                                                                                newData[value.id].providerPrice = e.target.value
                                                                            } else {
                                                                                let pricing = handleVariationPriceChange('topUp', e.target.value, value.pricing)
                                                                                newData[value.id].pricing = pricing
                                                                            }
                                                                            UpdateVariations(newData)
                                                                        }}
                                                                    />
                                                                    <InputGroupAddon addonType="append">
                                                                        <InputGroupText>AED</InputGroupText>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </Colxx>
                                                            {section !== 'provider' && <Colxx xxs="2">
                                                                <InputGroup>
                                                                    <Input className="customInput"
                                                                        disabled={user.role !== 'superAdmin'}
                                                                        value={value?.pricing?.discountedPrice}
                                                                        placeholder={"e.g. 200 AED"}
                                                                        type="number"
                                                                        onChange={(e) => {

                                                                            let newData = JSON.parse(JSON.stringify(updatedVariations))

                                                                            newData[value.id] = newData[value.id] || {}
                                                                            newData[value.id].service_id = selectedService.id
                                                                            newData[value.id].updated = true
                                                                            let pricing = handleVariationPriceChange('discountedPrice', e.target.value, value.pricing)
                                                                            newData[value.id].pricing = pricing
                                                                            UpdateVariations(newData)


                                                                        }}
                                                                    />
                                                                    <InputGroupAddon addonType="append">
                                                                        <InputGroupText>AED</InputGroupText>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </Colxx>}
                                                            {section !== 'provider' && <Colxx xxs={section !== 'provider' && "1"}>
                                                                {Number(updatedVariations[value.id]?.pricing?.total || updatedVariations[value.id].price).toFixed(2) + " AED"}
                                                            </Colxx>
                                                            }
                                                            {user.role === 'superAdmin' ? <Colxx xxs={section !== 'provider' && "1"} style={{
                                                                fontSize: '23px',
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                display: 'flex', alignItems: 'center',
                                                                justifyContent: 'flex-end'
                                                            }}>
                                                                {section === 'provider' &&

                                                                    <Button color="none"
                                                                        style={{ background: 'none', padding: '0', marginRight: '8px' }}
                                                                        onClick={() => {
                                                                            setSelectedVariation(value)
                                                                        }} ><i className="iconsminds-calendar-4"
                                                                            style={{ color: '#3498db', fontSize: '22px' }}
                                                                        /></Button>}
                                                                <Button
                                                                    color="none"
                                                                    style={{ background: 'none', padding: '0' }}
                                                                    onClick={() => {
                                                                        let newData = JSON.parse(JSON.stringify(updatedVariations))
                                                                        newData[value.id].deleted = true
                                                                        newData[value.id].updated = true

                                                                        UpdateVariations(newData)

                                                                        let serviceData = JSON.parse(JSON.stringify(selectedService))
                                                                        serviceData.allVariations[value.id] = value
                                                                        setSelectedService(serviceData)

                                                                    }} ><i className="simple-icon-trash"
                                                                        style={{ color: '#dc3545', fontSize: '22px' }}
                                                                    /></Button>

                                                            </Colxx> : ''}

                                                        </Row>
                                                    </div>)
                                            }}
                                        />
                                    </>

                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px 0' }}>No variations selected</div>
                            }
                        </Colxx>
                    </Row>
                </ModalBody>

                {user.role === 'superAdmin' ? <ModalFooter>
                    <Button outline color='danger' className='m-1' onClick={() => {
                        setSelectedService(false)
                        UpdateVariations({})
                    }}>Cancel</Button>
                    <Button color='primary'
                        className='m-1' onClick={() => {
                            updateService(updatedVariations, selectedService.id)
                        }}>Save</Button>
                </ModalFooter> : <ModalFooter>
                    <Button outline color='danger' className='m-1' onClick={() => {
                        setSelectedService(false)
                        UpdateVariations({})
                    }}>Dismiss</Button>

                </ModalFooter>}
            </Modal>}
            {selectedVariation && <Timings selectedVariation={selectedVariation} dismiss={() => setSelectedVariation(false)} updateTimings={(e) => {
                let newData = JSON.parse(JSON.stringify(updatedVariations))
                newData[selectedVariation.id] = newData[selectedVariation.id] || {}
                newData[selectedVariation.id].service_id = selectedService.id
                newData[selectedVariation.id].timings = e
                newData[selectedVariation.id].updated = true
                UpdateVariations(newData)
                setSelectedVariation(false)
            }} />}
        </Card>

    );
};

export default Service;